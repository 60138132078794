import entityHelper from '@/api/entityHelper';
import axios from 'axios';

export default {
    findPrograms(filters, page, pageSize, orderField, orderMode, year = undefined) {
        // return entityHelper.get("/spaces?page="  + page + "&perPage=" + pageSize + "&sort=" + orderField + "|" + orderMode)
        if(filters && !filters['year']){
            filters['year'] = year;
        }
        return entityHelper.findEntity("/programList", filters, page, pageSize, orderField, orderMode)
    },
    findProgramsBySubject(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity("/subject-programs", filters, page, pageSize, orderField, orderMode);
    },
    saveProgram(program, programId, year = undefined) {
        let url ='/program/' ;
        if(program.courseStage!=undefined){ program['courseStageId']=program.courseStage.id; }
        if (programId != undefined) {
            url += programId;
        }
        if(year != undefined || year != null){
            url += '?year='+year;
        }
        return entityHelper.saveEntity(program, url);
    },
    deleteProgram(programs, year = undefined) {
        let url = "/programs/delete";
        if(year != undefined || year != null){
            url += '?year='+year;
        }
        return entityHelper.deleteEntity(programs, url);
    },
    getProgramsUF(courseStageId, studyId, globalSearch){
        //console.log('courseStageId:'+courseStageId+' sutdyId:'+studyId)
        let queryString='';

        if (courseStageId != undefined) {
            queryString = '&course_stage_id='+courseStageId;
        }

        if (globalSearch != undefined) {
            queryString='&global_search='+globalSearch;
        }

        if (studyId != undefined) {
            queryString +='&study_id='+studyId;
        }

        return axios.get('/program?ufs=true'+queryString);
    },
    getAllProgramsByStage(stageId, schemaName = ''){
        return axios.get('/program?stage_id='+stageId, {params: {schemaName: schemaName}});
    },
    getAllProgramsByStudy(studyId){
        return axios.get('/program?study_id='+studyId);
    },
    addDeletePrograms(enrolmentId, addProgramIds, deleteProgramsIds, schemaName){
        // console.log("addDeletePrograms(enrolmentId ",enrolmentId," , addProgramIds: ",addProgramIds," - , deleteProgramsIds: ",deleteProgramsIds);
        let params = { 'addProgramIds': addProgramIds, 'deleteProgramsIds': deleteProgramsIds, 'schemaName': schemaName} ;
        // console.log("params; ",params);
        return axios.post("/enrolment/programs/"+enrolmentId, params);
    },
    getStudentsOtherPrograms(studentsIds, groupCourseStageId, year){
        let params = {studentsIds: studentsIds};
        if(year != undefined){
            params.year = year;
        }
        return axios.get('/programs/otherprograms/'+groupCourseStageId, {params});
    },
    getOtherStudentsProgram(programsIds, studentsIds, groupCourseStageId, year){
        let params = {programsIds: programsIds, studentsIds: studentsIds};
        if(year != undefined){
            params.year = year;
        }
        return axios.get('/programs/otherstudents/'+groupCourseStageId, {params});
    },
    getProgramByCourseStageAndSubject(courseStageId, subjectId){
        return axios.get('program/courseStage/'+courseStageId+'/subject/'+subjectId);
    },
    getProgramsOfStudent(studentId, yearCode = null, courseStageId = null) {
        let params = {studentId: studentId, yearCode: yearCode, courseStageId: courseStageId};
        return axios.get('/programs/getProgramsOfStudent', {params});
    },
    getProgram(id, year) {
        let url = '/program/' + id;

        if (year != null) {
            url += '?year=' + year;
        }

        return axios.get(url);
    },
    saveProgramEvaluableType(programId, evaluableTypeId, yearCode = null){
        let params = { 'programId': programId, 'evaluableTypeId': evaluableTypeId, 'yearCode': yearCode};
        return axios.post("/programs/saveEvaluableType", {params});
    },
    saveProgramsEvaluableTypes(assignations, yearCode = null){
        let params = { 'assignations': assignations, 'yearCode': yearCode};
        return axios.post("/programs/saveEvaluableTypes", {params});
    },
    getProgramEvaluableType(programId, filterYear= null){
        let params = {
            programId: programId,
        };
        if(filterYear && filterYear != undefined){
            params.year = filterYear;
        }
        return axios.get('/programs/'+programId+'/evaluableType', {params});
    },
    getAllPrograms(){
        return axios.get('/program/');
    },
    getProgramCourseStageName(programId, year){
        let params = {year: year};
        return axios.get('/program/'+programId+'/courseStageName', {params});
    }
}
