export default {
    "gradesSubjectsCenterName": "Nom del centre",
    "gradesSubjectsCenterLogo": "Logo del centre",
    "gradesSubjectsStudentName": "Nom de l'alumne",
    "gradesSubjectsStageName": "Nom del nivell",
    "gradesSubjectsEvaluationPeriodCode": "Codi del període d'avaluació",
    "gradesSubjectsCourseName": "Nom del curs",
    "gradesSubjectsGroupName": "Nom del grup",
    "gradesSubjectsSubjectsList": "Llistat de matèries",
    "gradesSubjectsSubjectsGrade": "Nota de cada materia",
    "gradesSubjectsEvaluationPeriodsCode": "Codi de cada període d'avaluació",
    "gradesSubjectsEvaluationPeriodsGrade": "Nota de cada període d'avaluació",
    "gradesSubjectsGradesComments": "Comentaris de notes",
    "gradesSubjectsTutorName": "Nom del tutor",
    "gradesSubjectsEvaluationPeriodName": "Nom del període d'avaluació",
    "gradesSubjectsEvaluables": "Avaluables",
    "gradesSubjectsYearName": "Any",
    "gradesOnlyOneSubjectCenterLogo": "Logo del centre",
    "gradesOnlyOneSubjectCourseCode": "Codi del curs",
    "gradesOnlyOneSubjectEvaluationPeriodName": "Nom del període d'avaluació",
    "gradesOnlyOneSubjectEvaluationPeriodsCode": "Codi del període d'avaluació",
    "gradesOnlyOneSubjectEvaluationPeriodsFinalGrade": "Nota final de cada període d'avaluació",
    "gradesOnlyOneSubjectEvaluationPeriodsGrade": "Nota de cada avaluable del període d'avaluació",
    "gradesOnlyOneSubjectTutorComments": "Comentaris del tutor",
    "gradesOnlyOneSubjectGradesComments": "Comentaris de notes",
    "gradesOnlyOneSubjectFinalGrade": "Nota final",
    "gradesOnlyOneSubjectStudentName": "Nom del alumne",
    "gradesOnlyOneSubjectGroupName": "Nom del grup",
    "gradesOnlyOneSubjectTutorName": "Nom del tutor",
    "gradesOnlyOneSubjectEvaluables": "Avaluables",
    "gradesOnlyOneSubjectYearName": "Any",
    "gradesCompetencesCenterLogo": "Logo del centre",
    "gradesCompetencesCenterName": "Nom del centre",
    "gradesCompetencesCenterAddress": "Direcció del centre",
    "gradesCompetencesCenterPostalCode": "Codi postal del centre",
    "gradesCompetencesCenterTown": "Població del centro",
    "gradesCompetencesCenterPhone": "Telèfon del centre",
    "gradesCompetencesStudentCompleteName": "Nom complet de l'alumne",
    "gradesCompetencesGroupName": "Nom del grup",
    "gradesCompetencesTutorCompleteName": "Nom del tutor",
    "gradesCompetencesCourseName": "Nom del curs",
    "gradesCompetencesEvaluationPeriodName": "Nom del període d'avaluació",
    "gradesCompetencesDate": "Data",
    "gradesCompetencesCompetenceList": "Llistat de competències",
    "gradesAreaCenterLogo": "Logo del centre",
    "gradesAreaCenterName": "Nom del centre",
    "gradesAreaGroupName":"Nom del grup",
    "gradesAreaStudentName":"Nom de l'alumne",
    "gradesAreaAbsenceCount": "Faltes",
    "gradesAreaRanking": "Posició",
    "gradesAreaPreschoolCenterLogo": "Logo del centre",
    "gradesAreaPreschoolCenterName": "Nom del centre",
    "gradesAreaPreschoolGroupName":"Nom del grup",
    "gradesAreaPreschoolStudentName":"Nom de l'alumne",
    "gradesAreaPreschoolTutorName": "Nom del tutor",
    "gradesAreaPreschoolEvaluationPeriodCode": "Codi període d'avaluació",
    "gradesAreaPreschoolYearName": "Any",
    "gradesAreaPreschoolAbsenceCount": "Faltes",
    "gradesAreaPreschoolAreaName": "Nom area",
    "gradesAreaPreschoolGradesComments": "Comentaris area",
    "enrolmentStudentContactValue": "Valor contacte alumne",
    "enrolmentSubjectName": "Nom matèria",
    "enrolmentStudentTutorName": "Nom tutor legal",
    "enrolmentStudentTutoraName": "Nom tutora legal",
    "enrolmentStudentTutorID": "Documento de identidad tutor legal",
    "enrolmentStudentTutoraID": "Documento de identidad tutora legal",
    "enrolmentTimetable": "Horari",
    "gradesAreaPreschoolCode": "Codi",
    "enrolmentClientName": "Nom del client",
    "enrolmentClientID": "Documento de identidad del client",
    "enrolmentClientAddreess": "Direcció del client",
    "enrolmentClientEmail": "Correu electrònic del client",
    "enrolmentClientPhone": "Telèfon del client",
    "enrolmentStudentEmail": "Correu electrònic de l'alumne",
    "enrolmentStudentPhone": "Telèfon de l'alumne",
    "enrolmentCourseStartDate": "Data inici curs",
    "enrolmentCourseEndDate": "Data final curs",
    "enrolmentCourseLanguage": "Idioma curs",
    "enrolmentLargeDate": "Data llarga",
    "printGradesGridCenterName": "Nom del centre",
    "printGradesGridCenterLogo": "Logo del centre",
    "printGradesGridStudentName": "Nom de l'alumne/a",
    "printGradesGridTutorName": "Nom del professor/a",
    "printGradesGridGroupName": "Nom del grup",
    "printGradesGridGrades": "Notes",
    "printGradesGridSubjectName": "Nom de la matèria",
    "printGradesGridDimensionName": "Nom de la competència",
    "printGradesGridCompetenceName": "Nom del criteri",
    "printGradesGridCompetenceGrade": "Nota del criteri",
    "printGradesGridPeriodName": "Convocatòria",
    "printGradesGridYearName": "Curs escolar",
    "printGradesGridDimensionGrades": "Notes competència",
    "enrolmentYearName": "Any acadèmic",
    "printGradesComments": "Informe de notes per comentaris",
    "printGradesCommentsStudentName": "Nom de l'alumne/a",
    "printGradesCommentsTutorName": "Nom del professor/a",
    "printGradesCommentsYearName": "Curs escolar",
    "printGradesCommentsSubjectName": "Nom de la matèria",
    "printGradesCommentsSubjectComments": "Comentaris",
    "printGradesCommentsGrades": "Notes",
    "communicationsName": "Nom",
    "communicationsSurname1": "Primer cognom ",
    "communicationsSurname2": "Segon cognom",
    "communicationsDni": "DNI",
    "communicationsAddress": "Adreça",
    "communicationsTown": "Població",
    "communicationsProvince": "Provincia",
    "communicationsCountry": "País",
    "communicationsZip": "Codi postal",
    "communicationsUsername": "Nom d'usuari",
    "communicationsYearActive": "Any acadèmic",
    "communicationsStage": "Nivell",
    "communicationsCourseStage": "Curs",
    "communicationsGroupCourseStage": "Grup",
    "communicationsSubject": "Matèria",
    "communicationsTimeSlot": "Franja horària",
    "communicationsRoom": "Aula",
    "communicationsTeacher": "Professor/a",
    "communicationsLogo": "Logo",
    "communicationsStudentCompleteName": "Nom alumnat complet",
    "communicationsCompleteName": "Nom complet",
    "shortDate": "Data curta",
    "gradesAreaEquivalencySelectorAE": "Valoració area (A-E)",
    "gradesAreaEquivalencySelectorIE": "Valoració area (Insuficient-Excel·lent)",
    "gradesAreaEquivalencySelector15": "Valoració area (1-5)",
    "gradesAreaEquivalencySelectorBasicSuperior": "Valoració area (Bàsic-Superior)",
    "gradesAreaEquivalencySelectedAchievedInProcess": "Valoració area (Aconseguit-En Progrés)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorAE": "Valoració area (A-E)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorIE": "Valoració area (Insuficient-Excel·lent)" ,
    "gradesAreaPartialStudyCertificateEquivalencySelector15": "Valoració area (1-5)",
    "gradesAreaPartialStudyCertificateEquivalencySelectorBasicSuperior": "Valoració area (Bàsic-Superior)",
    "gradesAreaPartialStudyCertificateEquivalencySelectedAchievedInProcess": "Valoració area (Alcanzado-En Progreso)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorAE": "Valoració area (A-E)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorIE": "Valoració area (Insuficient-Excel·lent)",
    "gradesAreaFinalStudyCertificateEquivalencySelector15": "Valoració area (1-5)",
    "gradesAreaFinalStudyCertificateEquivalencySelectorBasicSuperior": "Valoració area (Bàsic-Superior)",
    "gradesAreaFinalStudyCertificateEquivalencySelectedAchievedInProcess": "Valoració area (Alcanzado-En Progreso)",
    "printGradesEICACenterLogo": "Logo del centre",
    "printGradesEICACourseName": "Nom del curs",
    "printGradesEICAStudentName": "Nom de l'alumne",
    "printGradesEICATeacherName": "Nom del professor",
    "printGradesEICACompetencesTable": "Taula de competències",
    "printGradesEICACompetencesTableCompetenceName": "Nom de la competència",
    "printGradesEICACompetencesTableCompetencePercentage": "Percentatge competència",
    "printGradesEICACompetencesTableCompetenceGrade": "Nota competència",
    "printGradesEICAFinalGrade": "Nota final",
    "printGradesEICAObservationsTable": "Taula d'observacions",
    "printGradesEICAObservationsTableValue": "Observació",
    "gradesAreaBookTable": "Taula de notes",
    "gradesAreaBookTableName": "Nom de l'area",
    "gradesAreaBookTableGrade": "Nota de l'area",
    "gradesAreaBookTableAssessment": "Valoració de l'area",
    "gradesAreaBookTableSubjects": "Matèries de l'area",
    "gradesAreaBookTableSubjectsName": "Nom matèria",
    "gradesAreaBookTableSubjectsHourlyIntensity": "IH",
    "gradesAreaBookTableSubjectsGrade": "Nota matèria",
    "gradesAreaBookCenterLogo": "Logo del centre",
    "gradesAreaBookCenterName": "Nom del centre",
    "gradesAreaBookGroupName": "Nom del grup",
    "gradesAreaBookStudentName": "Nom de l'alumne",
    "gradesAreaBookTutorName": "Nom del tutor",
    "gradesAreaBookYearName": "Any",
    "gradesAreaBookPromoted": "Promociona",
    "gradesAreaBookReinforcementTable": "Taula de recuperacions",
    "gradesAreaBookReinforcementTableSubjectTable": "Taula de matèries de recuperació",
    "gradesAreaBookReinforcementTableSubjectTableName": "Nom matèria recuperada",
    "gradesAreaBookReinforcementTableSubjectTableGrade": "Nota matèria recuperada",
    "gradesAreaBookReinforcementTableSubjectTableAssessment": "Valoració matèria recuperada",
    "gradesAreaBookReinforcementTableSubjectTableDate": "Data recuperció",
    "gradesAreaBookEvaluationPeriod": "Període d'avaluació",
    "gradesAreaBookCode": "Codi",
    "gradesAreaBookPaperNumber": "Full",
    "gradesAreaBookEquivalencySelectorAE": "Valoració area (A-E)",
    "gradesAreaBookEquivalencySelectorIE": "Valoració area (Insuficient-Excel·lent)",
    "gradesAreaBookEquivalencySelector15": "Valoració area (1-5)",
    "gradesAreaBookEquivalencySelectorBasicSuperior": "Valoració area (Bàsic-Superior)",
    "gradesAreaBookEquivalencySelectedAchievedInProcess": "Valoració area (Aconseguit-En Progrés)",
    "gradesAreaBookReinforcementEquivalencySelectorAE": "Valoració recuperació (A-E)",
    "gradesAreaBookReinforcementEquivalencySelectorIE": "Valoració recuperació (Insuficient-Excel·lent)",
    "gradesAreaBookReinforcementEquivalencySelector15": "Valoració recuperació (1-5)",
    "gradesAreaBookReinforcementEquivalencySelectorBasicSuperior": "Valoració recuperació (Bàsic-Superior)",
    "gradesAreaBookReinforcementEquivalencySelectedAchievedInProcess": "Valoració recuperació (Aconseguit-En Progrés)",
    "gradesAreaPositionAverageLevelCenterLogo": "Logo del centre",
    "gradesAreaPositionAverageLevelPage": "Pàgina",
    "gradesAreaPositionAverageLevelYear": "Any",
    "gradesAreaPositionAverageLevelEvaluationPeriod": "Període d'avaluació",
    "gradesAreaPositionAverageLevelDate": "Data",
    "gradesAreaPositionAverageLevelTable": "Taula de promig de nivell",
    "gradesAreaPositionAverageLevelTableStage": "Nivell",
    "gradesAreaPositionAverageLevelTableAverage": "Promig nivell",
    "gradesAreaPositionAverageLevelTableStudentsTable": "Taula d'estudiants",
    "gradesAreaPositionAverageLevelTableStudentsTableCode": "Codi",
    "gradesAreaPositionAverageLevelTableStudentsTableStudent": "Estudiant",
    "gradesAreaPositionAverageLevelTableStudentsTableOrder": "Ordre",
    "gradesAreaPositionAverageLevelTableStudentsTableScore": "Puntuació",
    "gradesAreaPositionAverageLevelTableStudentsTableAverage": "Promig estudiant",
    "gradesAreaTable": "Taula d'àrea",
    "gradesAreaTableName": "Nom d'àrea",
    "gradesAreaTableGrade": "Nota d'àrea",
    "gradesAreaTableAssessment": "Valoració d'àrea",
    "gradesAreaTableEvaluationPeriods": "Períodes d'avaluació",
    "gradesAreaTableEvaluationPeriodsCode": "Codi de període d'avaluació",
    "gradesAreaTableEvaluationPeriodsGrade": "Nota de període d'avaluació",
    "gradesAreaTableSubjects": "Assignatures d'àrea",
    "gradesAreaTableSubjectsName": "Nom d'assignatura",
    "gradesAreaTableSubjectsHourlyIntensity": "Intensitat horària",
    "gradesAreaTableSubjectsGrade": "Nota d'assignatura",
    "gradesAreaTableSubjectsComments": "Comentaris d'assignatura",
    "gradesAreaTableSubjectsCommentsValue": "Valor de comentaris d'assignatura",
    "gradesAreaTableSubjectsCommentsCategory": "Categoria de comentaris d'assignatura",
    "gradesAreaCenterLogo": "Logotip del centre",
    "gradesAreaCenterName": "Nom del centre",
    "gradesAreaGroupName": "Nom del grup",
    "gradesAreaStudentName": "Nom de l'estudiant",
    "gradesAreaTutorName": "Nom del tutor",
    "gradesAreaEvaluationPeriodCode": "Codi de període d'avaluació seleccionat",
    "gradesAreaYearName": "Any",
    "gradesAreaAbsenceCount": "Faltes",
    "gradesAreaRanking": "Classificació",
    "gradesAreaTableAreaComments": "Comentaris d'àrea",
    "gradesAreaTableAreaCommentsValue": "Valor de comentaris d'àrea",
    "gradesAreaStudentTI": "TI de l'estudiant",
    "gradesAreaCourseName": "Nom del curs",
    "gradesAreaStageType": "Tipus d'etapa",
    "gradesAreaEvaluationPeriodName": "Nom de període d'avaluació",
    "gradesAreaFolEnrolment": "Número de matrícula",
    "gradesAreaDate": "Data",
    "gradesAreaStudentCode": "Codi de l'estudiant",
    "gradesAreaTableGradeEquivalencySelectorAE": "Selector d'equivalència de nota d'àrea (A-E)",
    "gradesAreaTableGradeEquivalencySelectorIE": "Selector d'equivalència de nota d'àrea (Insuficient-Excel·lent)",
    "gradesAreaTableGradeEquivalencySelector15": "Selector d'equivalència de nota d'àrea (1-5)",
    "gradesAreaTableGradeEquivalencySelectorBasicSuperior": "Selector d'equivalència de nota d'àrea (Bàsic-Superior)",
    "gradesAreaTableGradeEquivalencySelectedAchievedInProcess": "Selector d'equivalència de nota d'àrea (Aconseguit-En Progrés)",
    "gradesAreaPromoted": "Promociona",
    "gradesAreaPaperNumber": "Número de full",
    "gradesAreaReinforcementTable": "Taula de recuperació",
    "gradesAreaReinforcementTableSubjectTable": "Taula d'assignatures de recuperació",
    "gradesAreaReinforcementTableSubjectTableName": "Nom d'assignatura de recuperació",
    "gradesAreaReinforcementTableSubjectTableGrade": "Nota d'assignatura de recuperació",
    "gradesAreaReinforcementTableSubjectTableAssessment": "Valoració d'assignatura de recuperació",
    "gradesAreaReinforcementTableSubjectTableDate": "Data de recuperació",
    "gradesAreaTableFinalEvaluationPeriodCode": "Codi de període d'avaluació final",
    "gradesAreaTableFinalEvaluationPeriodGrade": "Nota de període d'avaluació final",
    "gradesAreaNotFinalEvaluationPeriods": "Períodes d'avaluació no finals",
    "gradesAreaNotFinalEvaluationPeriodsCode": "Codi de període d'avaluació no final",
    "gradesAreaNotFinalEvaluationPeriodsGrade": "Nota de període d'avaluació no final",
    "basicCommunicationsName": "Nom destinatari",
    "basicCommunicationsCenterLogo": "Logo centre",
    "basicCommunicationsStudentName": "Nom alumne",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorAE": "Selector d'equivalència de nota d'assignatura de recuperació (A-E)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorIE": "Selector d'equivalència de nota d'assignatura de recuperació (Insuficient-Excel·lent)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelector15": "Selector d'equivalència de nota d'assignatura de recuperació (1-5)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectorBasicSuperior": "Selector d'equivalència de nota d'assignatura de recuperació (Bàsic-Superior)",
    "gradesAreaReinforcementTableSubjectTableGradeEquivalencySelectedAchievedInProcess": "Selector d'equivalència de nota d'assignatura de recuperació (Aconseguit-En Progrés)",
    "printGradesEICAYearName": "Any",
    "printGradesEICAConvocatoryName": "Convocatòria",
    "gradesAreaCenterIcon": "Icona del centre",
    "gradesAreaPositionAverageLevelTableStudentsTableAmbit": "Àmbit",
    "gradesAreaPositionAverageLevelTableStudentsTableAmbitSubject": "Matèria",
    "gradesLevelAmbitCenterLogo": "Logo del centre",
    "gradesLevelAmbitPage": "Pàgina",
    "gradesLevelAmbitYear": "Any",
    "gradesLevelAmbitEvaluationPeriod": "Període d'avaluació",
    "gradesLevelAmbitDate": "Data",
    "gradesLevelAmbitStage": "Nivell",
    "gradesLevelAmbitStageTable": "Taula nivell",
    "gradesLevelAmbitStageTableStudent": "Estudiant",
    "gradesLevelAmbitStageTableStudentTable": "Taula d'estudiant",
    "gradesLevelAmbitStageTableStudentTableAmbit": "Àmbit",
    "gradesLevelAmbitStageTableStudentTableAmbitTable": "Taula d'Àmbit",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubject": "Assignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubjectTable": "Taula d'assignatures",
    "gradesLevelAmbitConvocatories": "Convocatòries",
    "gradesLevelAmbitMediumGrades": "mitjanes",
    "gradesLevelAmbitGrades": "notes",
    "gradesAmbitLevelCenterLogo": "Logo del centre",
    "gradesAmbitLevelPage": "Pàgina",
    "gradesAmbitLevelYear": "Any",
    "gradesAmbitLevelEvaluationPeriod": "Període d'avaluació",
    "gradesAmbitLevelDate": "Data",
    "gradesAmbitLevelAmbitTableStage": "Nivell",
    "gradesAmbitLevelAmbitTableStageTable": "Taula nivell",
    "gradesAmbitLevelAmbitTableStageTableStudent": "Estudiant",
    "gradesAmbitLevelAmbitTableStageTableStudentTable": "Taula d'estudiant",
    "gradesAmbitLevelAmbit": "Àmbit",
    "gradesAmbitLevelAmbitTable": "Taula d'Àmbit",
    "gradesAmbitLevelConvocatories": "Convocatòries",
    "gradesAmbitLevelGrades": "notes",
    "gradesAmbitLevelCourseCode": "Codi Curs",
    "gradesSubjectLevelCenterLogo": "Logo del centre",
    "gradesSubjectLevelPage": "Pàgina",
    "gradesSubjectLevelYear": "Any",
    "gradesSubjectLevelEvaluationPeriod": "Període d'avaluació",
    "gradesSubjectLevelDate": "Data",
    "gradesSubjectLevelSubjectTableStage": "Nivell",
    "gradesSubjectLevelSubjectTableStageTable": "Taula nivell",
    "gradesSubjectLevelSubjectTableStageTableStudent": "Estudiant",
    "gradesSubjectLevelSubjectTableStageTableStudentTable": "Taula d'estudiant",
    "gradesSubjectLevelSubject": "Assignatura",
    "gradesSubjectLevelSubjectTable": "Taula d'assignatures",
    "gradesSubjectLevelConvocatories": "Convocatòries",
    "gradesSubjectLevelGrades": "notes",
    "gradesSubjectLevelCourseCode": "Codi Curs",
    "enrolmentSubjectCenterLogo": "Logo del centre",
    "enrolmentSubjectPage": "Pàgina",
    "enrolmentSubjectYear": "Any",
    "enrolmentSubjectDate": "Data",
    "enrolmentSubjectEvaluationPeriod": "Període d'avaluació",
    "enrolmentSubjectCode": "Codi matèria",
    "enrolmentSubjectCourseCode": "Codi Curs",
    "enrolmentSubjectStage": "Nivell",
    "enrolmentSubjectEnrolments": "Núm. matrícules",
    "enrolmentSubjectStageEnrolments": "Matrícules Curs",
    "enrolmentRegistrationDate": "Data alta",
    "enrolmentTerminationDate": "Data baixa",
    "preregistrationNotificationsRecipientName": "Nom destinatari",
    "preregistrationNotificationsName": "Nom preinscripció",
    "preregistrationNotificationsDateActivation": "Data inici preinscripció",
    "preregistrationNotificationsDateDeactivation": "Data fi preinscripció",
    "preregistrationNotificationsAvailability": "Disponibilitat preinscripció",
    "preregistrationNotificationsLink": "Enllaç preinscripció",
    "preregistrationNotificationsCenter": "Nom centre",
    "preregistrationNotificationsLogo": "Logo centre",
    "currentDate": "Data actual",
    "enrolmentStudentId": "ID de l'alumne",
    "enrolmentStudentBrothers": "Germans de l'alumne",
    "enrolmentStudentBrothersSurnames": "Cognoms germà",
    "enrolmentStudentBrothersName": "Nom germà",
    "enrolmentStudentBrothersAddreess": "Adreça germà",
    "enrolmentStudentBrothersPostalCode": "Codi postal germà",
    "enrolmentStudentBrothersPopulation": "Població germà",
    "enrolmentStudentBrothersProvince": "Província germà",
    "enrolmentStudentBrothersDNI": "DNI germà",
    "enrolmentStudentBrothersEmail": "Correu electrònic germà",
    "enrolmentStudentBrothersPhone": "Telèfon germà",
    "enrolmentStudentBrothersMobile": "Mòbil germà",
    "enrolmentStudentTutors": "Tutor legal de l'alumne",
    "enrolmentStudentTutorsSurnames": "Cognoms tutor legal",
    "enrolmentStudentTutorsName": "Nom tutor legal",
    "enrolmentStudentTutorsAddreess": "Adreça tutor legal",
    "enrolmentStudentTutorsPostalCode": "Codi postal tutor legal",
    "enrolmentStudentTutorsPopulation": "Població tutor legal",
    "enrolmentStudentTutorsProvince": "Província tutor legal",
    "enrolmentStudentTutorsDNI": "DNI tutor legal",
    "enrolmentStudentTutorsEmail": "Correu electrònic tutor legal",
    "enrolmentStudentTutorsPhone": "Telèfon tutor legal",
    "enrolmentStudentTutorsMobile": "Mòbil tutor legal",
    "enrolmentStudentBrothersBirthdate": "Data de naixement germà",
    "enrolmentStudentBrothersStage": "Nivell germà",
    "enrolmentStudentBrothersCourseStage": "Curs germà",
    "enrolmentStudentBrothersGroupStage": "Grup germà",
    "enrolmentStudentName": "Nom de l'alumne",
    "enrolmentStudentSurnames": "Cognoms de l'alumne",
    "gradesProgressCenterLogo": "Logo centre",
    "gradesProgressStudentName": "Nom alumne/a",
    "gradesProgressTeachersName": "Nom professors",
    "gradesProgressYear": "Any lectiu",
    "gradesProgressCourseStage": "Curs",
    "gradesProgressTable": "Taula progrés",
    "gradesProgressTableSubject": "Matèria",
    "gradesProgressTableSubjectTable": "Taula Matèries",
    "gradesProgressTableSubjectTableCompetenceTable": "Taula competències",
    "gradesProgressTableSubjectTableCompetence": "Competència",
    "gradesProgressTableSubjectTableCompetenceTableCriterion": "Criteri",
    "gradesProgressTableSubjectTableCompetenceTableCriterionTable": "Taula de criteris",
    "gradesProgressTableSubjectTableCompetenceTableGrades": "Notes",
    "gradesProgressAttendanceTable": "Taula assistència",
    "gradesPrimaryCenterLogo": "Logo centre",
    "gradesPrimaryYear": "Any lectiu",
    "gradesPrimaryStudentName": "Nom alumne/a",
    "gradesPrimaryTeacherName": "Nom professor",
    "gradesPrimaryCourseStage": "Curs",
    "gradesPrimaryCompetenceTable": "Taula competències",
    "gradesPrimarySubjectsTable": "Taula Matèries",
    "gradesPrimaryCompetenceTableCompetence": "Competència",
    "gradesPrimarySubjectsTableSubject": "Matèria",
    "gradesPrimaryClassAttendance": "Resum d'assistència",
    "gradesPrimaryPercentageAttendance": "Percentatge d'assistència",
    "gradesPrimaryPercentageAbsence": "Percentatge d'absència",
    "gradesPrimaryPromoted": "Promoció estudiant",
    "gradesPrimaryComments": "Observacions",
    "monthlyReportCenterLogo": "Logo centre",
    "monthlyReportStudentName": "Nom alumne/a",
    "monthlyReportStudentId": "Id alumne/a",
    "monthlyReportTeacherName": "Nom professor",
    "monthlyReportCourseStage": "Curs",
    "monthlyReportSubjectTable": "Taula Matèries",
    "monthlyReportSubjectTableSubject": "Matèria",
    "monthlyReportSubjectTableTeacher": "Professor",
    "monthlyReportSubjectTableGrades": "Notes",
    "monthlyReportGpaTable": "Taula GPA",
    "monthlyReportGpaValue": "Valor GPA",
    "printGradesPrimaryGrillReport": "Report Notes primària",
    "printGradesGrillReportCenterLogo": "Logo centre",
    "printGradesGrillReportStudentName": "Nom estudiant",
    "printGradesGrillReportCourseStage": "Curs",
    "printGradesGrillReportYear": "Any escolar",
    "printGradesGrillReportGroup": "Grup",
    "printGradesGrillReportPeriods": "Periodes",
    "printGradesGrillReportConvocatories": "Convocatories",
    "printGradesGrillReportSubjectsTableSubject": "Assignatura",
    "printGradesGrillReportSubjectsTable": "Taula assignatures",
    "printGradesGrillReportSubjectsTableGrades": "Notes",
    "printGradesGrillReportAverages": "Promitjos",
    "printGradesGrillReportAttendanceTable": "Resum assistència",
    "printGradesGrillReportComments": "Comentaris",
    "learningReportCenterLogo": "Logo centre",
    "learningReportYear": "Any escolar",
    "learningReportStudentName": "Nom alumne/a",
    "learningReportTeacherName": "Professor",
    "learningReportSchoolPrincipal": "Director",
    "learningReportGradesTable": "Notes",
    "learningReportComments": "Comentaris",
    "monthlyReportHonorStudent": "Honor Student",
    "gradesAreaTableSubjectsEvaluationPeriodsGrade": "Nota assignatura per període",
    "gradesAreaTableSubjectsMedianGrade": "Nota mitjana de l'assignatura",
    "gradesLevelAmbitTableStageTableStudentTableAmbitTableSubjectTable": "Taula d'assignatures",
    "gradesLevelAmbitTableStageTableStudentTableAmbitTableSubjectTableGrades": "Notes d'assignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableTableSubject": "Nom assignatura",
    "gradesLevelAmbitStageTableStudentTableAmbitTableSubjectTableGrades": "Notes d'assignatura",
    "gradesAreaTableSubjectsGradeEquivalencySelectorBasicSuperior": "Selector d'equivalència de nota d'assignatura (Bàsic-Superior)",
    "printGradesLeadervilleStudent": "Alumnat",
    "printGradesLeadervilleCourse": "Curs",
    "printGradesLeadervilleTeachers": "Professors",
    "printGradesLeadervilleYear": "Any acadèmic",
    "printGradesLeadervilleCenterLogo": "Logo centre",
    "printGradesLeadervilleAmbitTable": "Taula àmbits",
    "printGradesLeadervilleAmbitName": "Nom àmbit",
    "printGradesLeadervilleAmbitCompetences": "Competències matèria",
    "printGradesLeadervilleAmbitCompetenceName": "Nom competència",
    "printGradesLeadervilleAmbitCompetenceGrades": "Notes competència",
    "printGradesLeadervilleAmbitSubjects": "Matèries àmbit",
    "printGradesLeadervilleAmbitSubjectName": "Nom matèria",
    "printGradesLeadervilleAmbitSubjectGrades": "Notes matèria",
    "printGradesLeaderVilleAmbitPeriods": "Convocatòries",
    "gradesPreescolarSaintJohnStudentName": "Nom de l'alumne",
    "gradesPreescolarSaintJohnTeachersNames": "Nom dels professors",
    "gradesPreescolarSaintJohnConvocatories": "Convocatòries",
    "gradesPreescolarSaintJohnEvaluableTypeValues": "Valors dels tipus avaluables",
    "gradesPreescolarSaintJohnAmbitsTables": "Taules d'àmbits",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTable": "Taula d'àmbit",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableAmbitName": "Nom de l'àmbit",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTable": "Taula de competències",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCompetenceName": "Nom de la competència",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTable": "Taula de criteris",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTableCriterionName": "Nom del criteri",
    "gradesPreescolarSaintJohnAmbitsTablesAmbitTableCompetenceTableCriterionTableGrade": "Nota del criteri",
    "gradesPreescolarSaintJohnCenterLogo": "Logo del centre",
    "gradesPreescolarSaintJohnComments": "Comentaris",
    "gradesAreaTableSubjectsGradeEquivalencySelectorBasicSuperior": "Selector d'equivalència de nota d'assignatura (Bàsic-Superior)",
    "gradesPreescolarSaintJohnYear": "Any",
    "gradesPreescolarSaintJohnStage": "Nivell",
    "gradesPreescolarSaintJohnCourse": "Curs",
}
